import { getLodop } from "@/utils/LodopFuncs";

export default function printOrder(items) {
  const LODOP = getLodop();
  LODOP.PRINT_INIT("质检报告");
  LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");
  LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
  LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);

  for (const item of items) {
    const totalQuantity =
      (item.inspection_standards[2]["cr"] ?? 0) +
      (item.inspection_standards[2]["maj"] ?? 0) +
      (item.inspection_standards[2]["min"] ?? 0);

    const pageContent = `
    <!DOCTYPE html>
    <html>
      <head>
        <style>
          td {
            border: 1px solid black;
            padding: 4px 8px;
          }
    
          .checkbox {
            width: 12px;
            height: 12px;
            border: 1px solid black;
            display: inline-block;
            margin-top: 2px;
            margin-bottom: -2px;
          }
        </style>
      </head>
    
      <body>
        <div style="width: 210mm; height: 297mm; position: relative">
          <div style="padding: 16px 16px 0 16px; height: 100%">
            <div>余姚市菲尔浦电器有限公司</div>
    
            <div style="text-align: center; font-weight: bold">
              <div style="font-size: 36px">来料检验报告</div>
            </div>
    
            <div style="display: flex; margin-bottom: 4px">
              <div style="width: 50%; text-align: left">编号: ${item.number ?? ""}</div>
              <div style="width: 50%; text-align: right">${item.inspection_date ?? ""}</div>
            </div>
    
            <div style="font-size: 14px">
              <table cellspacing="0" style="border: 2px solid black; border-collapse: collapse; width: 100%; text-align: center">
                <tr>
                  <td>物料名称</td>
                  <td colspan="2">${item.inspection_product_items[0]["goods_item"]["name"] ?? ""}</td>
                  <td>物料编号</td>
                  <td colspan="2">${item.inspection_product_items[0]["goods_item"]["number"] ?? ""}</td>
                  <td>订单编号</td>
                  <td>${item.stock_in_order_item.purchase_order_item?.number ?? ""}</td>
                </tr>
                <tr>
                  <td>供应商</td>
                  <td colspan="2">${item.stock_in_order_item.purchase_order_item?.supplier_name ?? ""}</td>
                  <td>来料数量</td>
                  <td colspan="2">${item.inspection_product_items[0]["total_quantity"] ?? ""}</td>
                  <td>抽样量</td>
                  <td>${item.inspection_product_items[0]["inspection_quantity"] ?? ""}</td>
                </tr>
                <tr>
                  <td>检验依据</td>
                  <td colspan="3">${item.inspection_basis.join(", ")}</td>
                  <td>检验结果</td>
                  <td colspan="3">${item.inspection_result_display ?? ""}</td>
                </tr>
                <tr>
                  <td>检验项目</td>
                  <td colspan="7">检验情况记录</td>
                </tr>
                <tr style="height: 58px">
                  <td>1. 外观</td>
                  <td colspan="7">${item.inspection_appearance ?? ""}</td>
                </tr>
                <tr style="height: 58px">
                  <td>2. 尺寸</td>
                  <td colspan="7">${item.inspection_dimensions ?? ""}</td>
                </tr>
                <tr style="height: 58px">
                  <td>3. 功能</td>
                  <td colspan="7">${item.inspection_function ?? ""}</td>
                </tr>
                <tr style="height: 58px">
                  <td>4. 测试</td>
                  <td colspan="7">${item.inspection_testing ?? ""}</td>
                </tr>
                <tr style="height: 58px">
                  <td>5. 试装</td>
                  <td colspan="7">${item.inspection_installation ?? ""}</td>
                </tr>
                <tr>
                  <td colspan="8">
                    <div style="display: flex; text-align: left">
                      <div style="flex: 1">检验员: ${item.inspector_item.name ?? ""}</div>
                      <div style="flex: 1">核准:</div>
                      <div style="flex: 1">总数: ${totalQuantity}</div>
                    </div>
                  </td>
                </tr>
                <tr style="height: 58px">
                  <td></td>
                  <td>采购部意见</td>
                  <td colspan="4"></td>
                  <td>签名</td>
                  <td></td>
                </tr>
                <tr style="height: 58px">
                  <td>M.R.B</td>
                  <td>生产部意见</td>
                  <td colspan="4"></td>
                  <td>签名</td>
                  <td></td>
                </tr>
                <tr style="height: 58px">
                  <td>物料评定会</td>
                  <td>技术部意见</td>
                  <td colspan="4"></td>
                  <td>签名</td>
                  <td></td>
                </tr>
                <tr style="height: 58px">
                  <td></td>
                  <td>品质部意见</td>
                  <td colspan="4"></td>
                  <td>签名</td>
                  <td></td>
                </tr>
                <tr>
                  <td rowspan="2" colspan="6">
                    <div style="display: flex; text-align: left">
                      <div style="flex: 1">M.R.B处理结果:</div>
                      <div style="flex: 4">
                        <div style="display: flex; flex-wrap: wrap; text-align: left">
                          <div style="flex: 1 1 50%">
                            <div class="checkbox"></div>
                            <span>挑选使用(Sort)</span>
                          </div>
                          <div style="flex: 1 1 50%">
                            <div class="checkbox"></div>
                            <span>加工/处理(Rework)</span>
                          </div>
                          <div style="flex: 1 1 50%">
                            <div class="checkbox"></div>
                            <span>让步接受(Accept of special)</span>
                          </div>
                          <div style="flex: 1 1 50%">
                            <div class="checkbox"></div>
                            <span>退货(Reject)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td colspan="2">责任单位</td>
                </tr>
                <tr>
                  <td>
                    <span>供方</span>
                    <div class="checkbox"></div>
                  </td>
                  <td>
                    <span>本司</span>
                    <div class="checkbox"></div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 12.5%; border: 1px solid #fff"></td>
                  <td style="width: 12.5%; border: 1px solid #fff"></td>
                  <td style="width: 12.5%; border: 1px solid #fff"></td>
                  <td style="width: 12.5%; border: 1px solid #fff"></td>
                  <td style="width: 12.5%; border: 1px solid #fff"></td>
                  <td style="width: 12.5%; border: 1px solid #fff"></td>
                  <td style="width: 12.5%; border: 1px solid #fff"></td>
                  <td style="width: 12.5%; border: 1px solid #fff"></td>
                </tr>
                <tr>
                  <td colspan="8">
                    <div style="display: flex">
                      <div style="flex: 1; text-align: left">加工/挑选不良品得理费用:</div>
                      <div style="flex: 1">
                        <span>加工/挑选:</span>
                        <span style="color: #fff">________</span>
                        <span>小时,</span>
                      </div>
                      <div style="flex: 1">
                        <span>每小时费用:</span>
                        <span style="color: #fff">________</span>
                        <span>元,</span>
                      </div>
                      <div style="flex: 1; text-align: right">
                        <span>总费用:</span>
                        <span style="color: #fff">____________</span>
                        <span>元</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
    
            <div style="display: flex; margin-top: 12px">
              <div style="flex: 1">审批:</div>
              <div style="flex: 1">批准:</div>
            </div>
          </div>
        </div>
      </body>
    </html>
    
                    `;

    LODOP.NewPage();
    LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
  }

  LODOP.PREVIEW();
}
