<template>
  <div>
    <a-card title="来料检验">
      <a-row gutter="16">
        <a-col :span="24" style="width: 240px; margin-bottom: 12px">
          <a-input-search v-model="searchForm.search" placeholder="来料检验单号" allowClear @search="search" />
        </a-col>
        <a-col :span="24" style="margin-bottom: 12px; width: 120px">
          <a-button-group>
            <a-button @click="printInspectionReport">打印来料检验单</a-button>
          </a-button-group>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="handleAdd">新增来料检验</a-button>
        </div>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :dataSource="dataItems"
          :loading="loading"
          :pagination="pagination"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="tableChange"
        >
          <template slot="number" slot-scope="value, item">
            <a @click="jumpInspectionReportDetail(item)">
              {{ item.number }}
            </a>
          </template>

          <template slot="order_number" slot-scope="value, item">
            <a v-if="item.stock_in_order_item.type === 'purchase'" @click="jumpPurchaseOrderDetail(item)">
              {{ item.stock_in_order_item.purchase_order_item.number }}
            </a>
            <a v-if="item.stock_in_order_item.type === 'production_stock_in'" @click="jumpProductionOrderDetail(item)">
              {{ item.stock_in_order_item.production_order_item.number }}
            </a>
          </template>

          <template slot="action" slot-scope="value, item">
            <TableAction :item="item" @update="handleUpdate" @delete="handleDelete" />
          </template>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { inspectionReportList } from "@/api/inspection";
import { userOption } from "@/api/option";
import lodopPrintInspectionReport from "./printInspectionReport";

export default {
  components: {
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "来料检验单号",
          dataIndex: "number",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "供应商",
          dataIndex: "supplier_name",
        },
        {
          title: "产品编号",
          dataIndex: "product_number",
        },
        {
          title: "产品名称",
          dataIndex: "product_name",
        },
        {
          title: "来料数量",
          dataIndex: "total_quantity",
        },
        {
          title: "检测数量",
          dataIndex: "inspection_quantity",
        },
        {
          title: "不合格数",
          dataIndex: "defective_quantity",
        },
        {
          title: "检测结果",
          dataIndex: "inspection_result",
        },
        {
          title: "检测日期",
          dataIndex: "inspection_date",
        },
        {
          title: "检测人员",
          dataIndex: "inspector",
        },
        {
          title: "状态",
          dataIndex: "status",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],
      searchForm: { search: "", page: 1, page_size: 16 },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      dataItems: [
        {
          id: 1,
          number: "IQC20240129002",
          supplier_name: "京东",
          product_number: "P00000000002",
          product_name: "手机",
          total_quantity: 100,
          inspection_quantity: 20,
          defective_quantity: 1,
          inspection_result: "不通过",
          inspection_date: "2024-02-01",
          inspector: "王工",
          status: "已确认",
        },
      ],
      userItems: [],

      selectedRowKeys: [],
      selectedRowItems: [],
    };
  },
  computed: {},
  methods: {
    initData() {
      this.list();

      userOption({ page_size: 99999 }).then((resp) => {
        this.userItems = resp.results;
      });
    },
    list() {
      // this.loading = true;
      // inspectionReportList(this.searchForm)
      //   .then((data) => {
      //     this.pagination.total = data.count;
      //     this.dataItems = data.results;
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    handleUpdate(item) {
      this.dataItems = this.$functions.replaceItem(this.dataItems, item);
    },
    handleDelete(item) {
      this.dataItems = this.$functions.removeItem(this.dataItems, item);
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    jumpPurchaseOrderDetail(item) {
      this.$router.push({
        path: "/purchasing/purchase_record_detail",
        query: { id: item.stock_in_order_item.purchase_order_item.id },
      });
    },
    jumpProductionOrderDetail(item) {
      this.$router.push({ path: "/production/detial", query: { id: item.stock_in_order_item.production_order_item.id } });
    },
    jumpInspectionReportDetail(item) {
      this.$router.push({ path: "/inspection/inspection_report_detail", query: { id: item.id } });
    },
    handleAdd() {
      this.$router.push({ path: "/inspection/inspection_report_create" });
    },
    onSelectChange(selectedRowKeys, selectedRowItems) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowItems = selectedRowItems;
    },
    printInspectionReport() {
      if (this.selectedRowItems.length === 0) {
        this.$message.warning("请选择要打印的质检报告");
        return;
      }

      lodopPrintInspectionReport(this.selectedRowItems);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
